import React, { useState } from "react";
import { FaClipboardList } from "react-icons/fa";

const JobNotesNew = (props) => {
  const notes = props.jobData.notes || [];
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div
      style={{
        width: "100%",
        alignSelf: "center",
        alignItems: "center",
        backgroundColor: "white",
        height: "70%",
      }}
    >
      {/* Notes List */}
      <div style={{ width: "100%", marginTop: 20 }}>
        {notes.map((value) => (
          <div
            style={{
              border: "2px solid #D9D9D9",
              borderRadius: "10px",
              width: "100%",
              minHeight: "90px",
              alignSelf: "center",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "16px",
              paddingLeft: 0,
              marginTop: 10,
            }}
            key={value.noteBody}
          >
            <div style={{ marginLeft: "4%" }}>
              <img
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                src="https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
                alt="Note Placeholder"
              />
            </div>
            <div style={{ width: "76%", marginLeft: "4%" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontSize: "18px", fontWeight: "700", margin: 0 }}>
                  {value.noteWho}
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#929292",
                    margin: 0,
                  }}
                >
                  {value.noteWhen}
                </p>
              </div>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  marginTop: "6px",
                  margin: 0,
                }}
              >
                {value.noteBody}
              </p>
            </div>
          </div>
        ))}

        {notes.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "80%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default JobNotesNew;
