import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import EmployeeCard from "./EmployeeCard";
import CalendarStrip from "./CalendarStrip";
import { BsChevronLeft } from "react-icons/bs";
import dayjs from "dayjs";
import { FiAlertOctagon } from "react-icons/fi";
import EditRouteMenu from "./EditRouteMenu";
import { BiCog } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

const SideMenu = (props) => {
  const selectedRoute = props.selectedRoute;
  const selectedBusiness = props.selectedBusiness;
  const [allEmployees, setAllEmployees] = useState([]);
  const [unAssignedServiceLocation, setUnAssignedServiceLocations] = useState(
    []
  );
  const [refresh, setRefresh] = useState(0);

  const [stopViewDisplay, setStopViewDisplay] = useState(false);
  const [stopViewData, setStopViewData] = useState({});
  const [unassignedCount, setUnassignedCount] = useState(0);
  const [menuOption, setMenuOption] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const serviceLocationsInRoute = props.serviceLocationsInRoute;
  const routeIsAlreadyActive = props.routeIsAlreadyActive;
  const [unscheduledSearchTerm, setUnscheduledSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const db = firebase.firestore();
  const cooldown = React.useRef(false);

  const handleGetDaysData = async (employees) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // For every employee and every day, fetch both the employee's route and the worker's default route order.
    const promises = employees.flatMap((employee) =>
      days.map((day) =>
        Promise.all([
          db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Routes")
            .doc(selectedRoute.routeId)
            .collection("Employees")
            .doc(employee.uid)
            .collection("Routes")
            .doc(day)
            .get(),
          db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Workers")
            .doc(employee.uid)
            .collection("Route")
            .doc(day)
            .get(),
        ]).then(([routeDoc, workerDoc]) => {
          const routeData = routeDoc.data();
          if (!routeData) return null; // Skip if no route data exists.
          const workerData = workerDoc.data();
          // Use the worker's defaultRouteOrder for the matching day.
          const defaultRouteOrderData = workerData
            ? workerData.defaultRouteOrder
            : routeData.defaultRouteOrder; // fallback if needed

          return {
            employee: employee.uid,
            day,
            dayData: routeData.route,
            defaultRouteOrderData,
          };
        })
      )
    );

    // Await all requests concurrently and filter out any null values.
    const results = await Promise.all(promises);
    return results.filter((item) => item !== null);
  };

  const handleActivateRoute = async () => {
    if (routeIsAlreadyActive) {
      const today = dayjs().format("MM/DD/YYYY");

      // I need to get the current order of the stops for the employees in the route

      const employeeRouteOrders = [];

      const dayDatas = await handleGetDaysData(props.employeesInRoute);

      console.log(dayDatas);

      for (let index = 0; index < props.employeesInRoute.length; index++) {
        const employee = props.employeesInRoute[index];
        const employeeUid = employee.uid;
        const employeeName = employee.name;

        // Find all stops for the employee across multiple days
        const employeeDayData = dayDatas.filter(
          (stop) => stop.employee === employeeUid
        );

        console.log(employeeDayData);

        // Group stops by day while keeping full stop objects
        const stopsByDay = {};

        employeeDayData.forEach((data) => {
          data.dayData.forEach((stop) => {
            if (!stopsByDay[stop.day]) {
              stopsByDay[stop.day] = [];
            }
            stopsByDay[stop.day].push(stop); // Store full stop object instead of just stopId
          });
        });

        // Sort stops for each day based on its specific defaultRouteOrderData
        const sortedEmployeeRouteOrder = Object.entries(stopsByDay).map(
          ([day, stops]) => {
            // Find the defaultRouteOrderData that matches the current `day`
            const defaultRouteOrderData =
              employeeDayData.find((data) =>
                data.dayData.some((s) => s.day === day)
              )?.defaultRouteOrderData || [];

            console.log(
              `Default Route Order for ${day}:`,
              defaultRouteOrderData
            );

            return {
              day,
              employeeRouteOrder: stops
                .sort(
                  (a, b) =>
                    defaultRouteOrderData.indexOf(a.stopId) -
                    defaultRouteOrderData.indexOf(b.stopId)
                )
                .map((stop) => stop.stopId), // Extract stopId after sorting
              employeeRouteOrderNames: stops
                .sort(
                  (a, b) =>
                    defaultRouteOrderData.indexOf(a.stopId) -
                    defaultRouteOrderData.indexOf(b.stopId)
                )
                .map((stop) => stop.name),
            };
          }
        );

        employeeRouteOrders.push({
          employeeUid: employeeUid,
          employeeName: employeeName,
          employeeRouteOrder: sortedEmployeeRouteOrder,
        });
      }

      console.log(employeeRouteOrders);

      console.log(today);
      if (!cooldown.current) {
        cooldown.current = true;
        setTimeout(() => {
          cooldown.current = false;
        }, 1000);
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) => {
            fetch(
              "https://us-central1-symbri-production.cloudfunctions.net/handleActivateRoute",
              {
                method: "POST",
                body: JSON.stringify({
                  selectedBusiness: selectedBusiness,
                  selectedRoute: selectedRoute,
                  serviceLocationsInRoute: props.serviceLocationsInRoute,
                  routeStartDate: props.routeStartDate,
                  today: today,
                  sendNotifications: true,
                  token: token,
                  routeIsAlreadyActive: routeIsAlreadyActive,
                  globalBiWeeklyStartDate: props.globalBiWeeklyStartDate,
                  employeeRouteOrders: employeeRouteOrders,
                }),
              }
            );
          });
        setTimeout(() => {
          props.setHasUnactivatedChanges(false);
        }, 1000);
      }
    } else {
      // open other slideout and close this one
      props.handleActivateRouteClick();
    }
  };

  useEffect(() => {
    const databaseSnapshot = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .onSnapshot((snapshots) => {
        let arrOfEmployees = [];
        setAllEmployees(arrOfEmployees);
        snapshots.docs.forEach((value) => {
          const data = value.data();
          arrOfEmployees.push(data);
        });
        setAllEmployees(arrOfEmployees);
        props.setEmployeesInRoute(arrOfEmployees);
        setRefresh(Math.random(0, 2));
      });

    return () => databaseSnapshot();
  }, []);

  useEffect(() => {
    let arrOfServiceLocations = [];

    // Resetting the unassigned service locations and count to initial state
    setUnAssignedServiceLocations([]);
    setUnassignedCount(0);

    for (let index = 0; index < props.serviceLocationsInRoute.length; index++) {
      const value = props.serviceLocationsInRoute[index];
      if (!value.assigned) {
        const stopCount = value.recurringStopType.stopIntervalPerWeek;

        for (let index = 0; index < stopCount; index++) {
          arrOfServiceLocations.push(value);
        }

        setUnAssignedServiceLocations(arrOfServiceLocations);
        setUnassignedCount(arrOfServiceLocations.length);
        setRefresh(Math.random());
      } else {
        const stopCount = value.recurringStopType.stopIntervalPerWeek;
        const markers = props.markers;
        const serviceLocationId = value.serviceLocationId;

        const assignedStopCount = markers.filter(
          (marker) =>
            marker.assigned && marker.serviceLocationId === serviceLocationId
        ).length;

        if (assignedStopCount < stopCount) {
          const addCount = stopCount - assignedStopCount;

          for (let index = 0; index < addCount; index++) {
            arrOfServiceLocations.push(value);
          }
        }
      }
    }

    setUnAssignedServiceLocations(arrOfServiceLocations);
    setUnassignedCount(arrOfServiceLocations.length);
    setRefresh(Math.random());
  }, [props.serviceLocationsInRoute, props.refresh]);

  const handleViewStop = (data) => {
    setStopViewData(data);
    setStopViewDisplay(true);
  };

  const handleGoBackStopView = () => {
    setStopViewData({});
    setStopViewDisplay(false);
  };

  const handleUpdateGlobalBiWeeklyStartDate = async (value) => {
    props.setGlobalBiWeeklyStartDate(value);
    await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .update({
        globalBiWeeklyStartDate: value,
      });
  };

  return (
    <div>
      <div
        className="hideScroll"
        style={{
          width: 340,
          position: "absolute",
          zIndex: 2000,
          marginBottom: "10px",
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          height: "88vh",
          backgroundColor: "white",
        }}
      >
        <div
          style={{ borderBottom: "1px solid #D9D9D9", minHeight: 48 }}
          className="flex items-center relative"
        >
          <div
            onClick={() => setMenuOption("all")}
            style={{
              borderBottom: menuOption === "all" ? "3px solid black" : "none",
              color: menuOption === "all" ? "black" : "gray",
              marginBottom: "-15px",
              width: 40,
              display: "flex",
              justifyContent: "center",
            }}
            className="ml-4 cursor-pointer pb-1"
          >
            <div className="pb-1">All</div>
          </div>
          <div
            style={{
              borderBottom:
                menuOption === "unscheduled" ? "3px solid black" : "none",
              color: menuOption === "unscheduled" ? "black" : "gray",
              marginBottom: "-15px",
            }}
            onClick={() => setMenuOption("unscheduled")}
            className="ml-4 cursor-pointer pb-1"
          >
            <div className="pb-1">Unscheduled</div>
          </div>
          <div className="ml-auto mr-4 cursor-pointer">
            <div
              style={{
                fontSize: 16,
                color: props.hasUnactivatedChanges
                  ? "green"
                  : !props.routeIsAlreadyActive
                  ? "green"
                  : "gray",
              }}
              onClick={() =>
                props.hasUnactivatedChanges || !props.routeIsAlreadyActive
                  ? handleActivateRoute()
                  : null
              }
            >
              Activate
            </div>
          </div>
        </div>
        {!stopViewDisplay && props.dayViewDisplay && (
          <div
            style={{
              backgroundColor: "#F9F9F9",
              height: "90%",
              marginTop: -2,
              paddingTop: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 44,
              }}
            >
              <div
                onClick={() => props.handleGoBackDayView()}
                style={{
                  marginLeft: "4%",
                  cursor: "pointer",
                }}
              >
                <FiArrowLeft color="black" size={18} />
              </div>

              <div
                onClick={() => props.handleGoBackDayView()}
                style={{ marginLeft: "2%", cursor: "pointer" }}
              >
                Back
              </div>
              <div
                style={{
                  display: "flex",
                  color: "white",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginLeft: "auto",
                  marginRight: "8%",
                }}
              >
                <div style={{ marginLeft: "4%", color: "black" }}>
                  {props.dayViewData.day}
                </div>
              </div>
            </div>

            <div className="flex items-center mt-2">
              <div style={{ marginLeft: "6%" }}>
                <img
                  className="inline-block h-10 w-10 rounded-full"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "3%",
                  color: "black",
                  fontSize: "0.88rem",
                  fontWeight: "600",
                }}
              >
                {props.dayViewData.employeeData.name.replace(
                  /(^\w{1})|(\s+\w{1})/g,
                  (letter) => letter.toUpperCase()
                )}
              </div>
              <div
                onClick={() =>
                  props.unscheduleEmployeeAll(
                    props.dayViewData.employeeData.uid
                  )
                }
                className="ml-auto mr-2 text-sm text-[#37A9FF] cursor-pointer"
              >
                Unschedule All
              </div>
            </div>

            <div
              className="hideScroll"
              style={{ maxHeight: "80%", overflowY: "scroll", marginTop: 12 }}
            >
              {props.dayViewData &&
                props.dayViewData.dayData
                  .sort((a, b) => {
                    if (props.dayViewData.defaultRouteOrderData) {
                      return (
                        props.dayViewData.defaultRouteOrderData.indexOf(
                          a.stopId
                        ) -
                        props.dayViewData.defaultRouteOrderData.indexOf(
                          b.stopId
                        )
                      );
                    } else {
                      // just ignore the sort
                      console.log("no default route order");
                      return 0;
                    }
                  })
                  .map((value, index) => (
                    <div
                      id={index}
                      key={value.tempStopId ? value.tempStopId : value.stopId}
                      stopId={
                        value.tempStopId ? value.tempStopId : value.stopId
                      }
                      style={{
                        minHeight: 74,
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #D9D9D9",
                        borderRadius: 10,
                        margin: 4,
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "4%",
                          fontSize: "0.8rem",
                          width: "7%",
                        }}
                      >
                        {index + 1}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          fontSize: "0.8rem",
                        }}
                      >
                        <div style={{ fontWeight: "600" }}>
                          {value.name.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </div>
                        <div
                          style={{
                            fontSize: "0.7rem",
                            textAlign: "right",
                            fontWeight: "600",
                            color: "#37A9FF",
                            marginTop: 2,
                          }}
                        >
                          {value.address.split(",")[0]}
                        </div>
                        <div
                          style={{
                            fontSize: "0.7rem",
                            textAlign: "left",
                            marginTop: 2,
                          }}
                        >
                          {value.stopNickname ||
                            value.recurringStopType?.stopName ||
                            value.name}
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "4%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          onClick={() => handleViewStop(value)}
                          style={{
                            fontSize: "0.7rem",
                            marginLeft: "auto",
                            cursor: "pointer",
                            textAlign: "left",
                            fontWeight: "600",
                            marginTop: 2,
                          }}
                        >
                          View Details
                        </div>
                        {value.recurringStopType?.recurringSchedule ===
                          "Every 2 Weeks" && (
                          <div
                            style={{
                              fontSize: "0.7rem",
                              textAlign: "right",
                              fontWeight: "600",
                              color: "#37A9FF",
                              cursor: "pointer",
                              marginTop: 4,
                            }}
                          >
                            <select
                              value={props.globalBiWeeklyStartDate} // Bind to the state
                              onChange={(e) =>
                                handleUpdateGlobalBiWeeklyStartDate(
                                  e.target.value
                                )
                              } // Handle state updates
                              style={{
                                fontSize: "0.7rem",
                                fontWeight: "600",
                                color: "#37A9FF",
                                cursor: "pointer",
                                border: "none",
                                background: "transparent",
                                appearance: "none",
                                textAlign: "right",
                              }}
                            >
                              <option value="Bi-Weekly | A">
                                Bi-Weekly | A
                              </option>
                              <option value="Bi-Weekly | B">
                                Bi-Weekly | B
                              </option>
                            </select>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}

        {stopViewDisplay && (
          <div
            style={{
              backgroundColor: "#F9F9F9",
              height: "90%",
              marginTop: -2,
              paddingTop: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 44,
              }}
            >
              <div
                onClick={() => handleGoBackStopView()}
                style={{
                  marginLeft: "4%",
                  cursor: "pointer",
                }}
              >
                <FiArrowLeft color="black" size={18} />
              </div>
              <div
                onClick={() => handleGoBackStopView()}
                style={{ marginLeft: "2%", cursor: "pointer" }}
              >
                Back
              </div>
              <div
                style={{
                  display: "flex",
                  color: "white",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginLeft: "auto",
                  marginRight: "8%",
                }}
              ></div>
            </div>

            <div className="flex items-center mt-2">
              <div style={{ marginLeft: "6%" }}>
                <img
                  className="inline-block h-10 w-10 rounded-full"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "3%",
                  color: "black",
                  fontSize: "0.88rem",
                  fontWeight: "600",
                }}
              >
                <div>
                  {props.dayViewData.employeeData.name.replace(
                    /(^\w{1})|(\s+\w{1})/g,
                    (letter) => letter.toUpperCase()
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: "8%" }}>
              <div
                style={{
                  margin: "4%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontSize: "0.8rem",
                }}
              >
                <div>Customer:</div>
                <div>{stopViewData.customerId.substring(0, 28)}...</div>
              </div>
              <div
                style={{
                  margin: "4%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontSize: "0.8rem",
                }}
              >
                <div>Address:</div>
                <div>{stopViewData.address.substring(0, 28)}...</div>
              </div>
              <div
                style={{
                  margin: "4%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontSize: "0.8rem",
                }}
              >
                <div>Stop Title:</div>
                <div>{stopViewData.recurringStopType.stopName}</div>
              </div>
              <div
                style={{
                  margin: "4%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontSize: "0.8rem",
                }}
              >
                <div>Frequency:</div>
                <div>{stopViewData.recurringStopType.recurringSchedule}</div>
              </div>
              <div
                style={{
                  margin: "4%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontSize: "0.8rem",
                }}
              >
                <div>Service Day:</div> <div>{props.dayViewData.day}</div>
              </div>
              {stopViewData.biWeeklyStartDate &&
                stopViewData.biWeeklyStartDate !== "none" && (
                  <div
                    style={{
                      margin: "4%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontSize: "0.8rem",
                    }}
                  >
                    <div>Bi-Weekly Start Date:</div>{" "}
                    <div className="flex items-center cursor-pointer">
                      Week A - {stopViewData.biWeeklyStartDate}
                    </div>
                  </div>
                )}

              <div
                style={{
                  margin: "4%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  fontSize: "0.8rem",
                }}
              >
                <div>Employee:</div> <div>{stopViewData.employeeUid}</div>
              </div>
            </div>
          </div>
        )}

        <div
          className="hideScroll"
          style={{
            display:
              !props.dayViewDisplay && menuOption !== "unscheduled"
                ? "flex"
                : "none",
            flexDirection: "column",
            width: "100%",
            maxHeight: "70vh",
            overflowY: "scroll",
            marginTop: 12,
          }}
        >
          {allEmployees.map((value) => (
            <EmployeeCard
              setEmployeePhotos={props.setEmployeePhotos}
              value={value}
              handleEditMode={props.handleEditMode}
              editModeDay={props.editModeDay}
              selectedBusiness={selectedBusiness}
              selectedRoute={selectedRoute}
              handleToggleHiddenDay={props.handleToggleHiddenDay}
              hiddenDays={props.hiddenDays}
              handleViewDay={props.handleViewDay}
              key={value.uid}
              handleCancelEditMode={props.handleCancelEditMode}
              handleToggleHiddenEmployee={props.handleToggleHiddenEmployee}
            />
          ))}
        </div>

        {menuOption === "unscheduled" && (
          <div
            style={{
              display: !props.dayViewDisplay ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                border: "1px solid #D9D9D9",
                width: "88%",
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 40,
                borderRadius: 5,
                marginTop: 14,
              }}
            >
              <input
                value={unscheduledSearchTerm}
                placeholder="Search"
                onChange={(change) =>
                  setUnscheduledSearchTerm(change.target.value)
                }
                style={{ width: "100%", outline: "none", paddingLeft: 12 }}
              />
              <div style={{ marginRight: "2%", fontSize: "1rem" }}>
                <AiOutlineSearch />
              </div>
            </div>
            <tbody className="hideScroll">
              {serviceLocationsInRoute
                .filter((serviceLocation) => !serviceLocation.assigned)
                .filter((serviceLocation) =>
                  unscheduledSearchTerm === ""
                    ? true
                    : serviceLocation.name
                        .toLowerCase()
                        .includes(unscheduledSearchTerm.toLowerCase())
                )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((serviceLocation, index) => (
                  <div
                    key={serviceLocation.serviceLocationId}
                    style={{
                      border: "1px solid #D9D9D9",
                      height: 72,
                      display: "flex",
                      alignItems: "center",
                      width: "88%",
                      margin: "auto",
                      marginTop: 12,
                      borderRadius: 5,
                    }}
                  >
                    <div style={{ marginLeft: "4%" }}>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
                        }
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "4%",
                      }}
                    >
                      <div style={{ fontSize: "0.8rem", fontWeight: "600" }}>
                        {serviceLocation.name.replace(
                          /(^\w{1})|(\s+\w{1})/g,
                          (letter) => letter.toUpperCase()
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          fontSize: "0.74rem",
                        }}
                      >
                        {serviceLocation.recurringStopType.stopName}
                      </div>
                    </div>
                  </div>
                ))}
            </tbody>
          </div>
        )}
      </div>

      {props.hasUnactivatedChanges && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            right: 420,
            top: 24,
            zIndex: 10,
          }}
        >
          <FiAlertOctagon color="red" size={20} />
          <div style={{ marginLeft: 10, color: "red", fontSize: "0.8rem" }}>
            This route has unactivated changes!
          </div>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
