import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import EmployeeCard from "./EmployeeCard";
import CalendarStrip from "./CalendarStrip";
import { BsChevronLeft } from "react-icons/bs";
import dayjs from "dayjs";
import {
  FiAlertOctagon,
  FiArrowLeft,
  FiArrowRight,
  FiCheck,
  FiCheckCircle,
  FiMenu,
  FiXSquare,
} from "react-icons/fi";
import UnscheduledStopCard from "./UnscheduledStopCard";
import { AiOutlineSearch } from "react-icons/ai";
import { BiCog } from "react-icons/bi";
import RescheduledStopCard from "./RescheduledStopCard";
import { IoAdd, IoFilter } from "react-icons/io5";
import { generateBusinessId } from "../../Tools/generateBusinessId";

const SideMenu = (props) => {
  const selectedRoute = props.selectedRoute;
  const selectedBusiness = props.selectedBusiness;
  const [unscheduledStops, setUnscheduledStops] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [stopViewDisplay, setStopViewDisplay] = useState(false);
  const [stopViewData, setStopViewData] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [unscheduledSearchTerm, setUnscheduledSearchTerm] = useState("");
  const [dragId, setDragId] = useState();
  const [allEmployees, setAllEmployees] = useState([]);
  const db = firebase.firestore();
  const [menuOption, setMenuOption] = useState("all");
  const [daysWithStops, setDaysWithStops] = useState([]);
  const [jobStopDates, setJobStopDates] = useState([]);
  const [rescheduledStops, setRescheduledStops] = useState([]);
  const [dragOverItem, setDragOverItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dropId, setDropId] = useState();
  const [dragIndex, setDragIndex] = useState(null);
  const [dropIndex, setDropIndex] = useState(null);
  const stopsContainerRef = useRef(null);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filters, setFilters] = useState([]); // Array of filter objects: { id, name, employees }
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isCreatingFilter, setIsCreatingFilter] = useState(false);
  const [newFilterName, setNewFilterName] = useState("");
  const [newFilterEmployees, setNewFilterEmployees] = useState([]);

  //handleOpenReportJob={handleOpenReportJob}
  //handleOpenReport={handleOpenReport}

  const loadSelectedFiltersFromLocalStorage = () => {
    const selectedFilters = localStorage.getItem("selectedFilters");
    if (selectedFilters) {
      setSelectedFilter(JSON.parse(selectedFilters));
    }
  };

  const addSelectedFilterToLocalStorage = (filter) => {
    localStorage.setItem("selectedFilters", JSON.stringify(filter));
  };

  const shouldShowStop = (biWeeklyStartDate, viewedDate, stopDay) => {
    if (biWeeklyStartDate === "none" || !biWeeklyStartDate) return true;
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Helper to parse a date string (mm/dd/yyyy) into a Date object
    const parseDate = (dateString) => {
      const [month, day, year] = dateString.split("/").map(Number);
      return new Date(year, month - 1, day);
    };

    // Parse input dates
    const startDate = parseDate(biWeeklyStartDate);
    const currentDate = parseDate(viewedDate);

    // Validate stopDay and get its index
    const stopDayIndex = daysOfWeek.indexOf(stopDay);
    if (stopDayIndex === -1)
      throw new Error("Invalid day of the week provided");

    // Check if the current date is before the biWeeklyStartDate
    if (currentDate < startDate) return false;

    // Ensure the viewed date matches the stop day
    if (currentDate.getDay() !== stopDayIndex) return false;

    // Calculate the difference in weeks between startDate and currentDate
    const timeDifference = currentDate - startDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    const weeksDifference = Math.floor(daysDifference / 7);

    // Return true if the difference in weeks is even (biweekly schedule)
    return weeksDifference % 2 === 0;
  };

  const fetchEmployeeData = async (value) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();

      const res = await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/fetchRouteDotsV2",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            value: value,
          }),
        }
      );

      const data = await res.json();

      if (data) {
        return data.allStops;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getFilters = async () => {
    const filtersSnapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("ActiveMapFilters")
      .get();

    const filters = filtersSnapshot.docs.map((doc) => doc.data());
    setFilters(filters);
  };

  useEffect(() => {
    getFilters();
    loadSelectedFiltersFromLocalStorage();
  }, []);

  useEffect(() => {
    if (props.routeStartDate) {
      setSelectedDate(props.routeStartDate);
    }
  }, [props.routeStartDate]);

  const processDots = async (allEmployees) => {
    // Fetch all employees' data in parallel
    const allData = await Promise.all(allEmployees.map(fetchEmployeeData));

    // Use sets for efficient uniqueness checks
    const daysOfWeeksWithStopsSet = new Set();
    const daysWithJobStopsSet = new Set();

    // Process all stops in a single pass
    for (const employeeStops of allData) {
      for (const stop of employeeStops) {
        if (stop.type === "Job Stop") {
          daysWithJobStopsSet.add(stop.stopDate);
        } else {
          daysOfWeeksWithStopsSet.add(stop.dayOfTheWeek);
        }
      }
    }

    // Convert sets back to arrays for your state
    setDaysWithStops(Array.from(daysOfWeeksWithStopsSet));
    setJobStopDates(Array.from(daysWithJobStopsSet));
  };

  const handleContainerDragOver = (e) => {
    if (stopsContainerRef.current) {
      const { top, bottom } = stopsContainerRef.current.getBoundingClientRect();
      const scrollThreshold = 50; // pixels from top/bottom to start scrolling
      const scrollSpeed = 10; // adjust speed as needed
      if (e.clientY < top + scrollThreshold) {
        stopsContainerRef.current.scrollBy(0, -scrollSpeed);
      } else if (e.clientY > bottom - scrollThreshold) {
        stopsContainerRef.current.scrollBy(0, scrollSpeed);
      }
    }
  };

  useEffect(() => {
    setAllEmployees(props.allEmployees);
    processDots(props.allEmployees);
  }, [props.allEmployees]);

  useEffect(() => {
    console.log(props.dayViewData);
    if (!props.dayViewData?.employee) {
      setUnscheduledStops(props.unscheduledMarkers);
    }
  }, [props.unscheduledMarkers]);

  useEffect(() => {
    if (props.rescheduledMarkers) {
      setRescheduledStops(props.rescheduledMarkers);
    }
  }, [props.rescheduledMarkers]);

  const handleDeleteEmployee = (value, totalCount) => {
    if (totalCount === 0) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("Employees")
        .doc(value.uid)
        .delete();
    } else {
      alert(
        "Cannot delete an employee with stops! Please remove all stops and try again!"
      );
    }
  };

  const handleSelectDate = (date) => {
    props.setRouteStartDate(dayjs(date).format("MM/DD/YYYY"));
    setSelectedDate(dayjs(date).format("MM/DD/YYYY"));
  };

  const handleGoBackDayView = () => {
    props.setDayViewDisplay(false);
    props.setDayViewData({});
    props.setStopsBeingViewed(null);
    props.setSelectedDayViewCard(null);
  };

  const handleGoBackStopView = () => {
    setStopViewData({});
    setStopViewDisplay(false);
  };

  const handleCheckedUnscheduledStop = (stop) => {
    let currentSelectedUnscheduledStops = props.selectedUnscheduledStops;

    currentSelectedUnscheduledStops.push(stop);
    props.setSelectedUnscheduledStops(currentSelectedUnscheduledStops);
    props.setRefresh(Math.random(0, 2));
  };

  const handleUnCheckedUnscheduledStop = (stop) => {
    let currentSelectedUnscheduledStops = props.selectedUnscheduledStops;
    const indexOfStop = currentSelectedUnscheduledStops.findIndex(
      (a) => a.stopId === stop.stopId
    );

    currentSelectedUnscheduledStops.splice(indexOfStop, 1);
    props.setSelectedUnscheduledStops(currentSelectedUnscheduledStops);
    props.setRefresh(Math.random(0, 2));
  };

  const handleSaveNewDataPerm = (orderArr) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(props.dayViewData.employee.uid)
      .collection("Route")
      .doc(dayjs(selectedDate).format("dddd"))
      .set(
        {
          defaultRouteOrder: orderArr,
          checkDistanceTime: true,
        },
        { merge: true }
      )
      .catch((e) => console.log(e));
  };

  const handleSaveNewData = (orderArr) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(props.dayViewData.employee.uid)
      .collection("Route")
      .doc(dayjs(selectedDate).format("dddd"))
      .get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const docData = documentSnapshot.data();
          let currentCustomRouteOrders = docData.customRouteOrders || [];
          let indexOfExisting = currentCustomRouteOrders.findIndex(
            (a) => a.date === dayjs(selectedDate).format("MM/DD/YYYY")
          );

          if (indexOfExisting !== -1) {
            currentCustomRouteOrders.splice(indexOfExisting, 1);
          }
          currentCustomRouteOrders.push({
            date: dayjs(selectedDate).format("MM/DD/YYYY"),
            order: orderArr,
          });

          db.collection("Businesses")
            .doc(selectedBusiness)
            .collection("Workers")
            .doc(props.dayViewData.employee.uid)
            .collection("Route")
            .doc(dayjs(selectedDate).format("dddd"))
            .set(
              {
                customRouteOrders: currentCustomRouteOrders,
                checkDistanceTime: true,
              },
              { merge: true }
            )
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleSelection = (type) => {
    setShowModal(false);

    if (type === "perm") {
      console.log("perm");
      let currentArrOfStops = props.dayViewData.data.sort(
        (a, b) =>
          props.customRouteOrder.indexOf(
            a.tempStopId ? a.tempStopId : a.stopId
          ) -
          props.customRouteOrder.indexOf(b.tempStopId ? b.tempStopId : b.stopId)
      );

      let value = currentArrOfStops[dragIndex].stopId;
      let currentOrder = [];

      for (let index = 0; index < currentArrOfStops.length; index++) {
        const stop = currentArrOfStops[index];
        currentOrder.push(stop.tempStopId ? stop.tempStopId : stop.stopId);
      }

      currentOrder.splice(dragIndex, 1);
      currentOrder.splice(dropIndex, 0, value);

      props.handleUpdateRouteOrder(currentOrder);
      handleSaveNewDataPerm(currentOrder);
      setDragOverItem(null);
      setRefresh(Math.random(0, 2));
      return;
    }

    if (type === "dateOnly" && dragIndex !== null && dropIndex !== null) {
      let currentArrOfStops = props.dayViewData.data.sort(
        (a, b) =>
          props.customRouteOrder.indexOf(
            a.tempStopId ? a.tempStopId : a.stopId
          ) -
          props.customRouteOrder.indexOf(b.tempStopId ? b.tempStopId : b.stopId)
      );

      let value = currentArrOfStops[dragIndex].stopId;
      let currentOrder = [];

      for (let index = 0; index < currentArrOfStops.length; index++) {
        const stop = currentArrOfStops[index];
        currentOrder.push(stop.tempStopId ? stop.tempStopId : stop.stopId);
      }

      currentOrder.splice(dragIndex, 1);
      currentOrder.splice(dropIndex, 0, value);

      props.handleUpdateRouteOrder(currentOrder);
      handleSaveNewData(currentOrder);
      setDragOverItem(null);
      setRefresh(Math.random(0, 2));
    }

    // Reset drag indices after processing
    setDragIndex(null);
    setDropIndex(null);
  };

  const handleDrop = (ev) => {
    const newDragIndex = Number(dragId);
    const newDropIndex = Number(ev.currentTarget.id);

    // Ensure dragIndex and dropIndex are valid numbers
    if (isNaN(newDragIndex) || isNaN(newDropIndex)) {
      console.error("Invalid drag or drop index");
      return;
    }

    // Store the drop indices and open the modal
    setDragIndex(newDragIndex);
    setDropIndex(newDropIndex);
    setShowModal(true);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();

    // Get the bounding box of the current item
    const bounding = e.currentTarget.getBoundingClientRect();
    const offset = e.clientY - bounding.top; // Distance from the top

    // If the mouse pointer is in the top half, set position to 'top', otherwise 'bottom'
    if (offset < bounding.height / 2) {
      setDragOverItem({ index, position: "top" });
    } else {
      setDragOverItem({ index, position: "bottom" });
    }
  };

  return (
    <div>
      <div
        style={{
          width: 360,
          position: "absolute",
          zIndex: 2000,
          marginBottom: "10px",
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          height: "88vh",
          backgroundColor: "white",
        }}
      >
        <div
          style={{ borderBottom: "1px solid #D9D9D9", minHeight: 48 }}
          className="flex items-center relative"
        >
          <div
            onClick={() => setMenuOption("all")}
            style={{
              borderBottom: menuOption === "all" ? "3px solid black" : "none",
              color: menuOption === "all" ? "black" : "gray",
              marginBottom: "-15px",
              width: 40,
              display: "flex",
              justifyContent: "center",
            }}
            className="ml-4 cursor-pointer pb-1"
          >
            <div className="pb-1">All</div>
          </div>
          <div
            style={{
              borderBottom:
                menuOption === "rescheduled" ? "3px solid black" : "none",
              color: menuOption === "rescheduled" ? "black" : "gray",
              marginBottom: "-15px",
            }}
            onClick={() => setMenuOption("rescheduled")}
            className="ml-4 cursor-pointer pb-1"
          >
            <div className="pb-1">Rescheduled</div>
          </div>
          <div
            style={{
              borderBottom:
                menuOption === "unscheduled" ? "3px solid black" : "none",
              color: menuOption === "unscheduled" ? "black" : "gray",
              marginBottom: "-15px",
            }}
            onClick={() => setMenuOption("unscheduled")}
            className="ml-4 cursor-pointer pb-1"
          >
            <div className="flex items-center">
              <div className="pb-1">Unscheduled</div>{" "}
              {unscheduledStops?.length > 0 && (
                <div className="bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-sm ml-1 mb-1">
                  {unscheduledStops.length}
                </div>
              )}
            </div>
          </div>
          {menuOption === "all" && !props.dayViewDisplay && (
            <div className="ml-4 cursor-pointer mt-2 relative">
              <IoFilter size={22} onClick={() => setShowFilterOptions(true)} />
              {showFilterOptions && (
                <div
                  className="absolute top-0 left-full ml-2 p-4 bg-white border rounded shadow-md w-96 flex flex-col"
                  style={{ zIndex: 3000 }}
                >
                  <div className="flex justify-between items-center">
                    <span className="font-bold text-lg">Filters</span>
                    <button
                      onClick={() => {
                        setShowFilterOptions(false);
                        setIsCreatingFilter(false);
                      }}
                      className="text-sm text-gray-600"
                    >
                      <FiXSquare size={20} />
                    </button>
                  </div>

                  {/* Show filter list if not creating a new filter */}
                  {!isCreatingFilter && (
                    <>
                      <div className="mt-2">
                        {filters.length > 0 ? (
                          filters.map((filter) => (
                            <div
                              key={filter.id}
                              onClick={() => {
                                if (
                                  selectedFilter &&
                                  selectedFilter.id === filter.id
                                ) {
                                  setSelectedFilter(null);
                                  addSelectedFilterToLocalStorage(null);
                                  return;
                                }
                                setSelectedFilter(filter);
                                addSelectedFilterToLocalStorage(filter);
                              }}
                              className={`p-2 border rounded mb-2 cursor-pointer flex items-center ${
                                selectedFilter &&
                                selectedFilter.id === filter.id
                                  ? "bg-blue-200"
                                  : "bg-white"
                              }`}
                            >
                              {filter.name.replace(
                                /(^\w{1})|(\s+\w{1})/g,
                                (letter) => letter.toUpperCase()
                              )}
                              <div
                                onClick={() => {
                                  db.collection("Businesses")
                                    .doc(selectedBusiness)
                                    .collection("ActiveMapFilters")
                                    .doc(filter.id)
                                    .delete();
                                  setFilters(
                                    filters.filter((f) => f.id !== filter.id)
                                  );
                                }}
                                className="ml-auto text-red-500"
                              >
                                X
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-gray-500">
                            No filters available
                          </div>
                        )}
                      </div>
                      <button
                        onClick={() => setIsCreatingFilter(true)}
                        className="mt-2 px-4 py-2 bg-[#4F46E5] text-white rounded"
                      >
                        New Filter
                      </button>
                    </>
                  )}

                  {/* New Filter creation UI */}
                  {isCreatingFilter && (
                    <div className="mt-2">
                      <input
                        type="text"
                        placeholder="Filter Name"
                        value={newFilterName}
                        onChange={(e) => setNewFilterName(e.target.value)}
                        className="border p-2 w-full rounded mb-2"
                      />
                      <div className="border p-2 rounded mb-2 max-h-40 overflow-y-auto">
                        {props.allEmployees.map((emp) => (
                          <div key={emp.uid} className="flex items-center mb-1">
                            <input
                              type="checkbox"
                              value={emp.uid}
                              checked={newFilterEmployees.includes(emp.uid)}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                if (isChecked) {
                                  setNewFilterEmployees([
                                    ...newFilterEmployees,
                                    emp.uid,
                                  ]);
                                } else {
                                  setNewFilterEmployees(
                                    newFilterEmployees.filter(
                                      (id) => id !== emp.uid
                                    )
                                  );
                                }
                              }}
                              className="mr-2"
                            />
                            <span>
                              {emp.name.replace(
                                /(^\w{1})|(\s+\w{1})/g,
                                (letter) => letter.toUpperCase()
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className="flex justify-end space-x-2">
                        <button
                          onClick={() => {
                            // Cancel new filter creation
                            setIsCreatingFilter(false);
                            setNewFilterName("");
                            setNewFilterEmployees([]);
                          }}
                          className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={async () => {
                            // Save new filter (using a simple Date.now() as an id)
                            const newFilter = {
                              id: generateBusinessId(),
                              name: newFilterName,
                              employees: newFilterEmployees,
                            };
                            await db
                              .collection("Businesses")
                              .doc(selectedBusiness)
                              .collection("ActiveMapFilters")
                              .doc(newFilter.id)
                              .set(newFilter);
                            setFilters([...filters, newFilter]);
                            setSelectedFilter(newFilter);
                            addSelectedFilterToLocalStorage(newFilter);
                            setIsCreatingFilter(false);
                            setNewFilterName("");
                            setNewFilterEmployees([]);
                          }}
                          className="px-4 py-2 bg-blue-600 text-white rounded"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {(menuOption === "unscheduled" || props.dayViewDisplay) && (
            <div className="ml-4 cursor-pointer mt-2 relative">
              <IoAdd
                size={22}
                onClick={() => {
                  props.setShowAddStop(true);
                  if (menuOption === "unscheduled") {
                    props.setAddStopMode("unscheduled");
                  } else if (props.dayViewDisplay) {
                    props.setAddStopMode("dayView");
                  }
                }}
              />
            </div>
          )}
        </div>

        {!props.dayViewDisplay && menuOption === "all" && (
          <CalendarStrip
            selectedDate={selectedDate}
            handleSelectDate={handleSelectDate}
            disabled={selectedRoute.active}
            serviceLocationsInRoute={props.serviceLocationsInRoute}
            daysWithStops={daysWithStops}
            jobStopDates={jobStopDates}
            rescheduleWholeRouteDisplay={props.rescheduleWholeRouteDisplay}
            rescheduleDisplay={props.rescheduleDisplay}
            oneTimeRescheduleDisplay={props.oneTimeRescheduleDisplay}
            scheduleOneTimeStopDisplay={props.scheduleOneTimeStopDisplay}
            viewDetailsRecDisplay={props.viewDetailsRecDisplay}
            viewDetailsOneTimeDisplay={props.viewDetailsOneTimeDisplay}
          />
        )}
        <div
          className="hideScroll"
          style={{
            maxHeight: "70vh",
            overflowY: "scroll",
            marginTop: 12,
          }}
        >
          {!props.dayViewDisplay &&
            menuOption === "all" &&
            allEmployees
              .filter((a) => {
                if (!selectedFilter) return true;

                if (selectedFilter?.employees.includes(a.uid)) {
                  return true;
                }
                return false;
              })
              .map((value) => (
                <EmployeeCard
                  handleDeleteEmployee={handleDeleteEmployee}
                  value={value}
                  handleEditMode={props.handleEditMode}
                  editModeDay={props.editModeDay}
                  selectedBusiness={selectedBusiness}
                  selectedRoute={selectedRoute}
                  handleToggleHiddenDay={props.handleToggleHiddenDay}
                  selectedDate={selectedDate}
                  handleViewDay={props.handleViewDay}
                  key={value.uid}
                  handleCancelEditMode={props.handleCancelEditMode}
                  handleOpenReschedule={props.handleOpenReschedule}
                  rescheduledStops={props.rescheduledStops}
                  setDayData={props.setDayData}
                  dayData={props.dayData}
                  forceEmployeeCardsReload={props.forceEmployeeCardsReload}
                  markersDataForProp={props.markersDataForProp}
                  hiddenEmployees={props.hiddenEmployees}
                  setStopsBeingViewed={props.setStopsBeingViewed}
                />
              ))}
        </div>
        {menuOption === "rescheduled" && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                border: "1px solid #D9D9D9",
                width: "88%",
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 40,
                borderRadius: 5,
                marginTop: 14,
              }}
            >
              <input
                value={unscheduledSearchTerm}
                placeholder="Search"
                onChange={(change) =>
                  setUnscheduledSearchTerm(change.target.value)
                }
                style={{ width: "100%", outline: "none", paddingLeft: 12 }}
              />
              <div style={{ marginRight: "2%", fontSize: "1rem" }}>
                <AiOutlineSearch />
              </div>
            </div>
            <div
              className="hideScroll"
              style={{
                height: "70vh",
                overflowY: "scroll",
              }}
              class="routeBuilderEditSideMenuScroll"
            >
              {rescheduledStops
                .filter((a) =>
                  unscheduledSearchTerm.length === 0
                    ? true
                    : a.customerName
                        .toLowerCase()
                        .includes(unscheduledSearchTerm.toLowerCase())
                )
                .map((value) => (
                  <RescheduledStopCard value={value} />
                ))}
            </div>
          </div>
        )}
        {menuOption === "unscheduled" && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                border: "1px solid #D9D9D9",
                width: "88%",
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 40,
                borderRadius: 5,
                marginTop: 14,
              }}
            >
              <input
                value={unscheduledSearchTerm}
                placeholder="Search"
                onChange={(change) =>
                  setUnscheduledSearchTerm(change.target.value)
                }
                style={{ width: "100%", outline: "none", paddingLeft: 12 }}
              />
              <div style={{ marginRight: "2%", fontSize: "1rem" }}>
                <AiOutlineSearch />
              </div>
            </div>
            <div
              className="hideScroll"
              style={{
                height: "70vh",
                overflowY: "scroll",
              }}
              class="routeBuilderEditSideMenuScroll"
            >
              {unscheduledStops
                .filter((a) => {
                  if (unscheduledSearchTerm.length === 0) return true;

                  let nameToCheck = "";

                  if (a.customer) {
                    // If there's a customer object, prefer customer.name if available, otherwise use customer.customerName
                    nameToCheck = a.customer.name
                      ? a.customer.name
                      : a.customer.customerName || "";
                  } else if (a.customerData) {
                    // If there's no a.customer but a.customerData is always a fallback with name
                    nameToCheck = a.customerData.name;
                  }

                  return nameToCheck
                    .toLowerCase()
                    .includes(unscheduledSearchTerm.toLowerCase());
                })

                .map((value) => (
                  <UnscheduledStopCard
                    setSelectedUnscheduledStop={
                      props.setSelectedUnscheduledStop
                    }
                    selectedUnscheduledStop={props.selectedUnscheduledStop}
                    selectedUnscheduledStops={props.selectedUnscheduledStops}
                    value={value}
                    handleViewDetails={props.handleViewDetails}
                    handleUnCheckedUnscheduledStop={
                      handleUnCheckedUnscheduledStop
                    }
                    handleCheckedUnscheduledStop={handleCheckedUnscheduledStop}
                    handleAddUnscheduledStopToDay={() =>
                      props.handleAddUnscheduledStopToDay(value, allEmployees)
                    }
                  />
                ))}
            </div>
          </div>
        )}

        {menuOption !== "rescheduled" &&
          menuOption !== "unscheduled" &&
          props.dayViewDisplay && (
            <div
              style={{
                backgroundColor: "#F9F9F9",
                height: "90%",
                marginTop: -2,
                paddingTop: 6,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  height: 44,
                }}
              >
                <div
                  onClick={() => handleGoBackDayView()}
                  style={{ marginLeft: "4%", cursor: "pointer" }}
                >
                  Go Back
                </div>
                <div
                  style={{
                    display: "flex",
                    color: "white",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginLeft: "auto",
                    marginRight: "8%",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => {
                      // minus 1 day from current date then call handleSelectDate with the new date

                      let newDate = new Date(selectedDate);

                      newDate.setDate(newDate.getDate() - 1);

                      handleSelectDate(newDate);

                      // so basically I just need to get the day data to be updated as well as stopsBeingViewed
                    }}
                    className="mr-1 cursor-pointer"
                  >
                    <FiArrowLeft color="black" size={18} />
                  </div>
                  <div style={{ color: "black" }}>
                    {dayjs(selectedDate).format("dddd")}
                  </div>
                  <div style={{ color: "black", marginLeft: 4 }}>
                    {dayjs(selectedDate).format("MM/DD/YYYY")}
                  </div>
                  <div
                    onClick={() => {
                      // add 1 day from current date then call handleSelectDate with the new date
                      const employeeData = props.dayViewData.employee;
                      let newDate = new Date(selectedDate);

                      newDate.setDate(newDate.getDate() + 1);

                      handleSelectDate(newDate);

                      // so basically I just need to get the day data to be updated as well as stopsBeingViewed
                    }}
                    className="ml-1 cursor-pointer"
                  >
                    <FiArrowRight color="black" size={18} />
                  </div>
                </div>
              </div>

              <div className="flex items-center mt-2">
                <div style={{ marginLeft: "6%" }}>
                  <img
                    className="inline-block h-10 w-10 rounded-full"
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
                    }
                  />
                </div>
                <div
                  style={{
                    marginLeft: "3%",
                    color: "black",
                    fontSize: "0.88rem",
                    fontWeight: "600",
                  }}
                >
                  <div>
                    {props.dayViewData.employee.name.replace(
                      /(^\w{1})|(\s+\w{1})/g,
                      (letter) => letter.toUpperCase()
                    )}
                  </div>
                </div>
                <div
                  onClick={() => {
                    props.setMoveRoutePools(props.dayViewData.data);
                    props.setRescheduleWholeRouteDisplay(true);
                  }}
                  className="text-sm ml-auto mr-2 text-[#37A9FF] cursor-pointer"
                >
                  Move Route
                </div>
              </div>

              <div
                className="hideScroll"
                ref={stopsContainerRef}
                onDragOver={handleContainerDragOver}
                style={{ maxHeight: "80%", overflowY: "scroll", marginTop: 12 }}
              >
                {props.dayViewData &&
                  props.dayViewData.data &&
                  props.dayViewData.data
                    .sort(
                      (a, b) =>
                        props.customRouteOrder.indexOf(
                          a.tempStopId ? a.tempStopId : a.stopId
                        ) -
                        props.customRouteOrder.indexOf(
                          b.tempStopId ? b.tempStopId : b.stopId
                        )
                    )
                    .map((value, index) => (
                      <div
                        onDragOver={(ev) => onDragOver(ev, index)}
                        onDragStart={handleDrag}
                        onDrop={handleDrop}
                        onClick={() => {
                          if (
                            props.selectedDayViewCard?.stopId ===
                            (value.tempStopId ? value.tempStopId : value.stopId)
                          ) {
                            props.handleDayViewCardClick(null);
                          } else {
                            props.handleDayViewCardClick(value);
                          }
                        }}
                        draggable
                        id={index}
                        key={value.tempStopId ? value.tempStopId : value.stopId}
                        stopId={
                          value.tempStopId ? value.tempStopId : value.stopId
                        }
                        style={{
                          minHeight: 74,
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #D9D9D9",
                          borderRadius: 10,
                          margin: 4,
                          cursor: "pointer",
                          boxShadow:
                            props.selectedDayViewCard?.stopId ===
                            (value.tempStopId ? value.tempStopId : value.stopId)
                              ? "0px 0px 5px 3px #37A9FF"
                              : "none",
                          borderTop:
                            dragOverItem?.index === index &&
                            dragOverItem?.position === "top"
                              ? "2px solid blue"
                              : "1px solid #D9D9D9",
                          borderBottom:
                            dragOverItem?.index === index &&
                            dragOverItem?.position === "bottom"
                              ? "2px solid blue"
                              : "1px solid #D9D9D9",
                        }}
                      >
                        <FiMenu
                          style={{
                            marginLeft: "4%",
                          }}
                        />
                        {(value.completedDates?.includes(
                          dayjs(selectedDate).format("MM/DD/YYYY")
                        ) ||
                          value.stopObject?.completedDates?.includes(
                            dayjs(selectedDate).format("MM/DD/YYYY")
                          )) && (
                          <div
                            style={{
                              width: "7%",
                              marginLeft: "2%",
                              marginRight: "2%",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#76D453",
                                width: 24,
                                height: 24,
                                borderRadius: 50,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <FiCheck color="white" size={18} />
                            </div>
                          </div>
                        )}

                        {!value.completedDates?.includes(
                          dayjs(selectedDate).format("MM/DD/YYYY")
                        ) &&
                          !value.stopObject?.completedDates?.includes(
                            dayjs(selectedDate).format("MM/DD/YYYY")
                          ) && (
                            <div
                              style={{
                                marginLeft: "4%",
                                fontSize: "0.8rem",
                                width: "7%",
                              }}
                            >
                              {index + 1}
                            </div>
                          )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            fontSize: "0.8rem",
                          }}
                        >
                          <div style={{ fontWeight: "600" }}>
                            {value.customerName
                              ? value.customerName.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )
                              : value.customerData.name.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                          </div>
                          <div
                            style={{
                              fontSize: "0.7rem",
                              textAlign: "right",
                              fontWeight: "600",
                              color: "#37A9FF",
                            }}
                          >
                            {value.address.split(",")[0]}
                          </div>
                          <div
                            style={{
                              fontSize: "0.7rem",
                              textAlign: "left",
                            }}
                          >
                            {value.stopNickname || value.name}
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "4%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {!value.completedDates?.includes(
                            dayjs(selectedDate).format("MM/DD/YYYY")
                          ) &&
                            !value.stopObject?.completedDates?.includes(
                              dayjs(selectedDate).format("MM/DD/YYYY")
                            ) && (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  props.handleViewDetails(value);
                                }}
                                style={{
                                  fontSize: "0.7rem",
                                  marginLeft: "auto",
                                  cursor: "pointer",
                                  textAlign: "left",
                                  fontWeight: "600",
                                }}
                              >
                                View Stop
                              </div>
                            )}
                          {(value.completedDates?.includes(
                            dayjs(selectedDate).format("MM/DD/YYYY")
                          ) ||
                            value.stopObject?.completedDates?.includes(
                              dayjs(selectedDate).format("MM/DD/YYYY")
                            )) && (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                value.type !== "recStop"
                                  ? props.handleOpenReportJob(value)
                                  : props.handleOpenReport(value);
                              }}
                              style={{
                                fontSize: "0.7rem",
                                marginLeft: "auto",
                                cursor: "pointer",
                                textAlign: "left",
                                fontWeight: "600",
                              }}
                            >
                              View Report
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          )}
      </div>

      {props.hasUnactivatedChanges && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            left: "34%",
            top: 72,
            zIndex: 1000,
          }}
        >
          <FiAlertOctagon color="red" size={60} />
          <div style={{ marginLeft: 20, color: "red" }}>
            This route has unactivated changes!
          </div>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[2000]">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96 ml-52">
            <h3 className="text-lg font-semibold mb-4">Move Stop</h3>
            <p className="text-gray-700 mb-6">
              Do you want to move this stop permanently or just for this date?
            </p>
            <div className="flex justify-between">
              <button
                onClick={() => handleSelection("perm")}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Permanent
              </button>
              <button
                onClick={() => handleSelection("dateOnly")}
                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
              >
                This Date Only
              </button>
              <button
                onClick={() => {
                  setShowModal(false);
                  setDragOverItem(null); // Reset blue line indicator
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
